import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import Script from 'next/head';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Provider } from 'react-redux';
import { changeLocale } from '../components/common/general';
import store from '../store';
import LayoutContextContainer from '../components/contexts/LayoutContextContainer';

import '../styles/globals.css';
import '../styles/modal.css';
import '../styles/wheel.css';
import '../styles/responsive.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/elements.css';
import '../styles/grid.css';
import 'antd/es/message/style/index.css';
import 'antd/es/badge/style/index.css';
import 'antd/es/tooltip/style/index.css';
import 'antd/es/progress/style/index.css';
import '../styles/StrengthMeter.css';
import '../styles/animated.css';
import '../styles/theme.css';
import '../styles/banner.css';
import '../styles/jackpot.rolling.css';

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const locale = changeLocale(router.locale);

  return (
    <>
      <Script
        id='gtag-base'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${'GTM-KR9KZLFM'}');
          `,
        }}
      />
      <Head>
        <title>{t('meta.Homepage_metaTitle')}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' />
        <meta name='keywords' content={t('meta.keywords')} />
        <meta name='image' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />
        <meta property='og:image' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />
        <meta property='og:image:secure_url' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />

        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-title' content='PPX7' />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Cassino Online Brasil - Fortune Tiger - PPX7.com' />
        <meta
          name='twitter:description'
          content='PPX7: O principal cassino online do Brasil, renomado pelo emocionante jogo Fortune Tiger. Experimente jogos de alto nível e grandes vitórias no Cassino Online Brasil - Fortune Tiger - PPX7.com.'
        />
        <meta name='twitter:url' content='https://x.com/ppx7brazil/status/1808144270865613240' />
        <meta name='twitter:image' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />
        <meta name='twitter:site' content='@ppx7brazil' />

        <meta
          name='p:domain_verify'
          content='2e7eadf8d6c143b58885b3e1f48b895d'
        />

        <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />

        <meta property='og:type' content='website' />

        <meta name='robots' content='all' />

        <link rel='icon' href='/favicon.ico' />
        <link rel='apple-touch-icon' href='/favicon.ico' />

        {/* hreflang */}
        <link rel='alternate' hrefLang='x-default' href='https://ppx7.com' />
        <link rel='alternate' hrefLang='br' href='https://ppx7.com/pt-BR' />
        <link rel='alternate' hrefLang='pt' href='https://ppx7.com/pt-BR' />
        <link rel='alternate' hrefLang='pt-BR' href='https://ppx7.com/pt-BR' />

        <link rel='alternate' hrefLang='en' href='https://ppx7.com/en-BR' />
        <link rel='alternate' hrefLang='en-BR' href='https://ppx7.com/en-BR' />
        <link rel='alternate' hrefLang='en-US' href='https://ppx7.com/en-BR' />
      </Head>

      <Provider store={store}>
        <LayoutContextContainer>
          <Component {...pageProps} />
        </LayoutContextContainer>
      </Provider>
    </>
  );
}

export async function getServerSideProps(context) {
  const { query, locale } = context;
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      query,
      branchId: process.env.BRANCH_ID,
    },
  };
}

export default appWithTranslation(MyApp);
