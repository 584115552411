import { createSlice } from '@reduxjs/toolkit';

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    loading: false,
    accountLoading: false,
    windowSize: {
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
      height: typeof window !== 'undefined' ? window.innerHeight : 0,
      orientation: 'landscape', // 'portrait'
    },
    isMobile: false,
    alert: [],
    balance: null,
    locale: 'en',
    selected: null,
    loginShow: false,
    registerShow: false,
    gameExternalURL: '',
    promoShow: false,
    mobileMenuSelected: null,
    forgotPasswordShow: false,
    activeProviderSelected: null,
    isFullScreen: false,
    referralSpinWheelCount: 0,
    storeReferralSpinCount: 0,
    sideBar: false,
    setActiveBonusClaim: {}
  },
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setAccountLoading: (state, action) => ({
      ...state,
      accountLoading: action.payload,
    }),
    setWindowSize: (state, action) => ({
      ...state,
      windowSize: action.payload,
    }),
    setIsMobile: (state, action) => ({
      ...state,
      isMobile: action.payload,
    }),
    setIsFullScreen: (state, action) => ({
      ...state,
      isFullScreen: action.payload,
    }),
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setBalance: (state, action) => ({
      ...state,
      balance: action.payload,
    }),
    setLocale: (state, action) => ({
      ...state,
      locale: action.payload,
    }),
    setSelected: (state, action) => ({
      ...state,
      selected: action.payload,
    }),
    setLoginShow: (state, action) => ({
      ...state,
      loginShow: action.payload,
    }),
    setRegisterShow: (state, action) => ({
      ...state,
      registerShow: action.payload,
    }),
    setGameExternalURL: (state, action) => ({
      ...state,
      gameExternalURL: action.payload,
    }),
    setPromoShow: (state, action) => ({
      ...state,
      promoShow: action.payload,
    }),
    setMobileMenuSelected: (state, action) => ({
      ...state,
      mobileMenuSelected: action.payload,
    }),
    setForgotPasswordShow: (state, action) => ({
      ...state,
      forgotPasswordShow: action.payload,
    }),
    setActiveProviderSelected: (state, action) => ({
      ...state,
      activeProviderSelected: action.payload,
    }),
    setReferralSpinWheelCount: (state, action) => ({
      ...state,
      referralSpinWheelCount: action.payload
    }),
    setStoreReferralSpinCount: (state, action) => ({
      ...state,
      storeReferralSpinCount: action.payload
    }),
    setSideBar: (state, action) => ({
      ...state,
      sideBar: action.payload
    }),
    setActiveBonusClaim: (state, action) => ({
      ...state,
      activeBonusClaim: action.payload
    })
  },
});

// Action creators are generated for each case reducer function
const { reducer: setting, actions } = settingSlice;
export const SettingActions = actions;
export default setting;
