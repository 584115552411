import {
  chineseLanguageLogo,
  malayLanguageLogo,
  languageIcon,
  portugueseLanguageLogo,
} from '../../images';

export function multipleDummyData(n, data) {
  const latestData = [];
  for (let i = 0; i < n; i++) {
    latestData.push(data);
  }
  return latestData;
}

export function generateHourList() {
  const hourList = [];
  for (let i = 0; i < 12; i++) {
    hourList.push(i);
  }
  return hourList;
}

export function genrateMinList() {
  const minList = [];
  for (let i = 0; i < 59; i++) {
    minList.push(i);
  }
  return minList;
}

export function changeLocale(locale) {
  switch (locale) {
  case 'en-BR':
    return 'English';
  case 'cn':
    return '中文';
  case 'my':
    return 'Malay';
  case 'pt-BR':
    return 'Portuguese';
  }
}

export function changeLanguageLogo(locale) {
  switch (locale) {
  case 'en-BR':
    return languageIcon;
  case 'cn':
    return chineseLanguageLogo;
  case 'my':
    return malayLanguageLogo;
  case 'pt-BR':
    return portugueseLanguageLogo;
  }
}

export function selectorStyle(type, required) {
  const isTimeSelector = type == 'time';
  const inputStyles = {
    menu: (provided, state) => ({
      backgroundColor: '#415768',
      borderRadius: 5,
      borderWidth: 0.5,
    }),
    control: (styles) => ({
      ...styles,
      fontSize: 12,
      borderRadius: 4,
      marginBottom: 5,
      height: isTimeSelector ? 20 : 40,
      border: 'none',
      backgroundColor: '#415768',
    }),
    option: (styles, {
      data, isDisabled, isFocused, isSelected,
    }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? 'rgb(237, 237, 237)'
          : isFocused
            ? 'rgb(237, 237, 237)'
            : undefined,
      fontSize: 12,
      cursor: 'pointer',
      color: 'black',
      ':active': {
        ...styles[':active'],
        background: '#f2f2f2',
      },
      ':hover': {
        ...styles[':active'],
        background: '#f2f2f2',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'black',
    }),
  };
  return inputStyles;
}

export function selectorStyleDeposit(type, required) {
  const isTimeSelector = type === 'time';
  const inputStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#1A242D',
      borderRadius: 5,
      borderWidth: 0.5,
    }),
    control: (styles) => ({
      ...styles,
      fontSize: 12,
      borderRadius: 4,
      marginBottom: 5,
      height: isTimeSelector ? 20 : 40,
      border: 'none',
      backgroundColor: '#1A242D',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? '#1A242D'
          : isFocused
            ? '#FFFFFF1A'
            : '#1A242D',
      color: isDisabled
        ? undefined
        : isSelected
          ? 'white'
          : isFocused
            ? 'white'
            : 'white',
      fontSize: 12,
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#FFFFFF1A',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#FFFFFF1A',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'white',
    }),
  };
  return inputStyles;
}

export function maskUsername(username) {
  // Replace all characters except the first three with asterisks, make it constant five asterisks no matter the length of the username
  return username.slice(0, 3) + '*'.repeat(5);
}
