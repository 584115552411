import bg from './bg.jpg';
import logo from './header/logo.png';
import hamburgersvg from './header/Hamburger_1.png';
import speakerImg from './speaker.png';
import noData from './noData.png';
import loading_gif from './loading.gif';
import getBonus from './header/getBonus.png';
import languagesvg from './language/language.png';
import language from './language/english_language_icon.png';
import bgVip from './bg_vip.webp';

import chinese_language_logo from './language/chinese_language_logo.png';
import malay_language_logo from './language/malay_language_logo.png';
import portuguese_language_logo from './language/portuguese_language_icon.png';
import avatar_icon from './profile/avatar_icon.svg';
import card_icon from './bank/card.png';
import usdt_icon from './bank/usdt.png';
import empty_banklist from './bank/empty_banklist.png';
import top_up_usdt_logo from './wallet/top_up_usdt_icon.png';
import top_up_logo from './wallet/top_up_logo.png';
import duitNow_Icon from './wallet/duitNow_Icon.png';
import profile_deposit_icon from './dashboard/profileModal/profile_deposit_icon.png';
import profile_withdraw_icon from './dashboard/profileModal/profile_withdraw_icon.png';
import profile_history_icon from './dashboard/profileModal/profile_history_icon.png';
import logo_test_icon from './dashboard/profileModal/logo_test_icon.png';
import profile_my_profile_icon from './dashboard/profileModal/my_profile_icon.png';
import profile_change_password_icon from './dashboard/profileModal/change_password_icon.png';
import profile_logout from './dashboard/profileModal/profile_logout.png';
import logout_icon from './profile/logout_icon.png';
import profile_bank_info_icon from './dashboard/profileModal/profile_bank_info_icon.png';
import profile_language_icon from './dashboard/profileModal/profile_language_icon.png';
import profile_crown_icon from './profile/profile_crown_icon.png';
import profile_help_icon from './profile/profile_help_icon.png';
import profile_logout_icon from './profile/profile_logout_icon.png';
import profile_my_bets_icon from './profile/profile_my_bets_icon.png';
import profile_payment_history_icon from './profile/profile_payment_history_icon.png';
import profile_personal_data_icon from './profile/profile_personal_data_icon.png';
import profile_setting_icon from './profile/profile_setting_icon.png';
import profile_special_offer_icon from './profile/profile_special_offer_icon.png';
import profile_wallet_icon from './profile/profile_wallet_icon.png';

import walletDeposit from './wallet/Deposit.svg';
import walletWithdrawal from './wallet/Withdraw.svg';
import walletHistory from './wallet/History.svg';
import verify_in_progress_icon from './profile/verify_in_progress_icon.png';
import verification_fail_icon from './profile/verification_fail_icon.png';
import verify_email_icon from './profile/verify_email_icon.png';
import verify_fail_icon_small from './profile/verify_fail_icon_small.png';
import verify_identity_icon from './profile/verify_identity_icon.png';
import verify_pass_icon_small from './profile/verify_pass_icon_small.png';
import verify_phone_icon from './profile/verify_phone_icon.png';

import walletDepositSelected from './wallet/Deposit Selected.svg';
import walletWithdrawalSelected from './wallet/Withdraw Selected.svg';
import walletHistorySelected from './wallet/History Selected.svg';

import gameBanner4d from './game/banner/4d.png';
import gameBannerCard from './game/banner/card.png';
import gameBannerCard2 from './game/banner/card2.png';
import gameBannerInstantGameBR from './game/banner/instant-gameBR.webp';
import gameBannerInstantGame from './game/banner/Instant-games.webp';
import gameBannerCasino from './game/banner/casino.png';
import gameBannerCasino2 from './game/banner/casino2.png';
import gameBannerTable from './game/banner/table.webp';
import gameBannerTableBR from './game/banner/tableBR.webp';
import gameBannerLive from './game/banner/live.webp';
import gameBannerLiveBR from './game/banner/liveBR.webp';
import gameBannerSport from './game/banner/sports.webp';
import gameBannerFishing from './game/banner/Fishing.webp';
import gameBannerFishingBR from './game/banner/FishingBR.webp';
import gameBannerSportBR from './game/banner/sportsBR.webp';
import gameBannerSlots from './game/banner/slots.webp';
import gameBannerSlotsBR from './game/banner/slotsBR.webp';

import cryptoIconDeposit from './deposit/Cryto.png';
import customerServiceIconDeposit from './deposit/Customer Service TopUp.png';
import onlineTransferIconDeposit from './deposit/Online Transfer.png';
import vpayIconDeposit from './deposit/Vpay.png';
import rarepidIconDeposit from './deposit/rarepid.png';
import ospayIconDeposit from './deposit/ospay.png';
import depositFail from './deposit/fail.png';
import depositSuccess from './deposit/success.png';
import depositSuccessGreen from './deposit/success-green.png';

// VIP
import vip_normal from './vip/normal.webp';
import vip_bronze from './vip/bronze.webp';
import vip_silver from './vip/silver.webp';
import vip_gold from './vip/gold.webp';
import vip_platinum from './vip/platinum.webp';
import vip_diamond from './vip/diamond.webp';

// Hamburger Menu
import menu_home from './hamburgerMenu/menu_home.svg';
import menu_history from './hamburgerMenu/menu_history.svg';
import menu_profile from './hamburgerMenu/menu_profile.png';
import menu_promotion from './hamburgerMenu/menu_promotion.svg';
import menu_wallet from './hamburgerMenu/menu_wallet.svg';
import faq_icon from './hamburgerMenu/menu_faq.svg';
import menu_referral from './hamburgerMenu/menu_referral.svg';
import menu_vip from './hamburgerMenu/menu_vip.svg';
import menu_logout from './hamburgerMenu/menu_logout.svg';
import top_menu_profile from './hamburgerMenu/profile.png';
import top_menu_notification from './hamburgerMenu/notification.png';

import cimb_logo from './bank/cimb_logo.png';
import hl_logo from './bank/hl_logo.png';
import maybank_logo from './bank/maybank_logo.png';
import pb_logo from './bank/pb_logo.png';
import rhb_logo from './bank/rhb_logo.png';
import bsn_logo from './bank/bsn_logo.png';
import ambank_logo from './bank/ambank_logo.png';
import ewallet_logo from './bank/ewallet_logo.png';
import tng_logo from './bank/tng_logo.png';
import tng_merchant from './bank/tng.png';
import duitNow_logo from './bank/duitNow_logo.png';

import ambank_logo_payment_selected from './bank/Payment Selected/ambank_logo.png';
import bsn_logo_payment_selected from './bank/Payment Selected/bsn_logo.png';
import cimb_logo_payment_selected from './bank/Payment Selected/cimb_logo.png';
import duitNow_logo_payment_selected from './bank/Payment Selected/duitNow_logo.png';
import hl_logo_payment_selected from './bank/Payment Selected/hl_logo.png';
import maybank_logo_payment_selected from './bank/Payment Selected/maybank_logo.png';
import pb_logo_payment_selected from './bank/Payment Selected/pb_logo.png';
import tng_logo_payment_selected from './bank/Payment Selected/tng_logo.png';
import rhb_logo_payment_selected from './bank/Payment Selected/rhb_logo.png';

// Ramadan
import claimNow from './events/claimNow.gif';
import checkinCharacter1 from './events/1.png';
import checkinCharacter2 from './events/2.png';
import checkinCharacter3 from './events/3.png';
import checkinCharacter4 from './events/4.png';
import checkinTitle from './events/title.png';
import checkinBg from './events/bg.png';
import afterclaim from './events/coin/afterclaim.png';
import beforeclaim from './events/coin/beforeclaim.gif';
import eventscheckin from './events/coin/checkin.png';
import eventsCoin from './events/coin/coin.png';

// Wheel Spin
import luckySpin from './events/wheelSpin/2B1_1 3.gif';
import wheelSpinOuterSlow from './events/wheelSpin/wheel_spin_outer_slow.gif';
import wheelSpinOuter from './events/wheelSpin/wheel_spin_outer.gif';
import wheelSpinInner from './events/wheelSpin/wheel_spin_inner.png';
import wheelSpinArrow from './events/wheelSpin/wheel_spin_arrow.png';
import wheelSpinButton from './events/wheelSpin/wheel_spin_button.png';

import twitterLogo from './social/twitterLogo.png';
import youtueLogo from './dashboard/youtubeLogo.png';
import lineLogo from './dashboard/lineLogo.png';
import telegramLogo from './dashboard/telegramLogo.png';
import fb from './social/fb.png';
import ig from './social/ig.png';
import youtube from './social/youtube.png';
import wa from './social/wa.png';
import livechat from './social/livechat.png';
import tg from './social/tg.png';
import wechat from './social/wechat.png';
import tiktok from './social/tiktok.png';

import profileRecommend_icon from './profile/profile_recommend.png';
import history_icon from './profile/history.png';
import transaction_icon from './profile/transaction.png';
import change_password_icon from './profile/lock.png';
import global_icon from './profile/global.png';
import withdraw_pw_icon from './profile/withrawalPw.svg';
import inviteIcon from './profile/invite.svg';
import shareButton from './profile/share.png';
import copyButton from './profile/copy.png';
import refer_chat_poster from './dashboard/refer_chat_poster.png';

import cashInImage from './howToSpin/deposit.png';
import spinImage from './howToSpin/spin.png';
import ticketImage from './howToSpin/ticket.png';
import airPodImage from './howToSpin/airpod.png';
import bagImage from './howToSpin/bag.png';
import goldImage from './howToSpin/gold.png';
import appleWatchImage from './howToSpin/iwatch.png';
import watchImage from './howToSpin/watch.png';
import iphoneImage from './howToSpin/iphone.png';

// Game vendor
import youlian from './gameVendor/youlian.png';
import onextwogaming from './gameVendor/1x2gaming.png';
import betsoft from './gameVendor/betsoft.png';
import biggaming from './gameVendor/big gaming.png';
import BnG from './gameVendor/BnG.png';
import evolution from './gameVendor/evolution.png';
import EvoPlay from './gameVendor/EvoPlay.png';
import ezugi from './gameVendor/ezugi.png';
import habanero from './gameVendor/habanero.png';
import hacksaw from './gameVendor/hacksaw.png';
import jdb from './gameVendor/jdb.png';
import jili from './gameVendor/jili.png';
import joker from './gameVendor/joker.png';
import kingmaker from './gameVendor/kingmaker.png';
import live22 from './gameVendor/live22.png';
import m8bet from './gameVendor/m8bet.png';
import microgaming from './gameVendor/microgaming.png';
import Netent from './gameVendor/Netent.png';
import nolimitcity from './gameVendor/nolimit-city.png';
import OneTouch from './gameVendor/OneTouch.png';
import playnGO from './gameVendor/playnGO.png';
import Playson from './gameVendor/Playson.png';
import playtech from './gameVendor/playtech.png';
import pragmaticplay from './gameVendor/pragmatic play.png';
import quickspin from './gameVendor/quickspin.png';
import redtiger from './gameVendor/red tiger.png';
import relaxgaming from './gameVendor/relaxgaming.png';
import sexybaccarat from './gameVendor/sexy baccarat.png';
import spadegaming from './gameVendor/spadegaming.png';
import Spinomenal from './gameVendor/Spinomenal.png';
import superspade from './gameVendor/superspade.png';
import sv388 from './gameVendor/sv388.png';
import wazdan from './gameVendor/wazdan.png';
import YGGDRASIL from './gameVendor/YGGDRASIL.png';
import mobileProviderLogo from './gameVendor/mobileProviderLogo.gif';
import webProviderLogo from './gameVendor/webProviderLogo.gif';
import pinnacleLogo from './gameVendor/pinnacle.png';
import allbetLogo from './gameVendor/allbet.png';

import newDashboardWalletBalance from './dashboard/newDashboard/walletBalance.png';
import newDashboardPointBalance from './dashboard/newDashboard/pointBalance.png';

import newDashboard_lineDesktop from './dashboard/newDashboard/lineDesktop.png';

import biggest_win_icon_hover from './dashboard/newDashboard/highestEarnings/biggest_win_icon_hover.webp';
import biggest_win_icon from './dashboard/newDashboard/highestEarnings/biggest_win_icon.webp';
import latest_earnings_icon_hover from './dashboard/newDashboard/highestEarnings/latest_earnings_icon_hover.webp';
import latest_earnings_icon from './dashboard/newDashboard/highestEarnings/latest_earnings_icon.webp';
import live_earnings_icon_hover from './dashboard/newDashboard/highestEarnings/live_earnings_icon_hover.webp';
import live_earnings_icon from './dashboard/newDashboard/highestEarnings/live_earning_icon.webp';
import default_thumbnail_icon from './dashboard/newDashboard/highestEarnings/default_thumbnail.webp';

import loading1 from './dashboard/loading/1.png';
import loading2 from './dashboard/loading/2.png';
import loading3 from './dashboard/loading/3.png';
import loading4 from './dashboard/loading/4.png';
import loading5 from './dashboard/loading/5.png';
import depositPaymentLoading from './dashboard/loading/deposit-payment-loading.gif';

import gameDefaultImage from './game/Group 5812.png';
import gameBadge from './game/Group 1321314805.png';

import menu_icon_faq from './navMenu/menuIconFAQ.png';
import menu_icon_history from './navMenu/menuIconHistory.png';
import menu_icon_live_stream from './navMenu/menuIconLiveStream.png';
import menu_icon_promotion from './navMenu/menuIconPromotion.png';
import menu_icon_referral from './navMenu/menuIconReferral.png';
import menu_icon_vip from './navMenu/menuIconVIP.png';
import menu_icon_casino from './navMenu/menuIconCasino.png';
import menu_icon_fishing from './navMenu/menuIconFishing.png';
import menu_icon_live_casino from './navMenu/menuIconLiveCasino.png';
import menu_icon_slots from './navMenu/menuIconSlots.png';
import menu_icon_sport from './navMenu/menuIconSport.png';
import menu_icon_terms_conditions from './navMenu/menuIconTermsAndCondition.png';
import menu_icon_ipl from './navMenu/nav_menu_ipl_icon.svg';
import menu_icon_ipl_colored from './navMenu/nav_menu_ipl_icon_colored.png';
import menu_icon_news from './navMenu/menuIconNews.png';

import menuIconpromotion from './navMenu/slideMenu/menuIcon-promotion.png';
import menuIconalllive from './navMenu/slideMenu/menuIcon-all-live.png';
import menuIconfavorities from './navMenu/slideMenu/menuIcon-favorities.png';
import menuIcontopparlays from './navMenu/slideMenu/menuIcon-top-parlays.png';

import menuIconhistoryleft from './navMenu/slideMenu/menuIcon-history.webp';
import menuIconpromotiongreenleft from './navMenu/slideMenu/menuIcon-promotion-green.png';
import menuIconreferralleft from './navMenu/slideMenu/menuIcon-referral.webp';
import menuIcontermleft from './navMenu/slideMenu/menuIcon-term.webp';
import menuIconvipleft from './navMenu/slideMenu/menuIcon-vip.png';
import menuIconfaqleft from './navMenu/slideMenu/menuIcon-faq.png';

import menuchevronLeft from './navMenu/menuchevronLeft.png';

import menufish from './navMenu/menu-fish.svg';
import menulivecasino from './navMenu/menu-livecasino.svg';
import menulive from './navMenu/menu-live.svg';
import menuspot from './navMenu/menu-spot.svg';
import menuslot from './navMenu/menu-slot.svg';
import menucasino from './navMenu/menu-casino.svg';

import navmenuCardGames from './navMenu/menu-card-games.png';
import navmenuInstantGames from './navMenu/menu-instant-games.png';
import navmenuLiveEvents from './navMenu/menu-live-events.png';
import navmenuSlot from './navMenu/menu-slot.png';
import navmenuLivecasino from './navMenu/menu-live-casino.png';
import navmenuTableGames from './navMenu/menu-table-games.png';
import navmenuUpcomingMatches from './navMenu/menu-upcoming-matches.png';
import navmenuVirtualSports from './navMenu/menu-virtual-sports.png';

import sideMenuBack from './navMenu/slideMenu/side-menu-back.png';
import sideMenuLanguageDown from './navMenu/slideMenu/side-menu-language-down.png';

import navbarCasino from './navbarBottom/casino.png';
import navbarCouponRegular from './navbarBottom/coupon-Regular.png';
import navbarMain from './navbarBottom/main.webp';
import navbarMenu from './navbarBottom/menu.png';
import navbarProfile from './navbarBottom/profile.png';
import navbarSports from './navbarBottom/sports.png';
import navbarHistory from './navbarBottom/history.png';
import navbarReferral from './navbarBottom/referral.png';
import navbarVip from './navbarBottom/vip.png';
import navbarPromo from './navbarBottom/promo.png';

import sportType from './dashboard/newDashboard/sport-type.png';
import sportTitle from './dashboard/newDashboard/sport-title.png';
import welcometo from './dashboard/newDashboard/welcomeTo.webp';
import sportTeamA from './dashboard/newDashboard/teamA.png';
import sportTeamB from './dashboard/newDashboard/teamB.png';
import sportBell from './dashboard/newDashboard/bell.svg';
import sportChart from './dashboard/newDashboard/chart.svg';
import sportStar from './dashboard/newDashboard/star.svg';

import downloadApk from './dashboard/newDashboard/downloadApk.png';

import badmintonIcon from './dashboard/newDashboard/sportIcons/Badminton_D.png';
import baseballIcon from './dashboard/newDashboard/sportIcons/Baseball_D.png';
import basketballIcon from './dashboard/newDashboard/sportIcons/Basketball_D.png';
import boxingIcon from './dashboard/newDashboard/sportIcons/Boxing_D.png';
import cricketIcon from './dashboard/newDashboard/sportIcons/Cricket_D.png';
import curlingIcon from './dashboard/newDashboard/sportIcons/Curling_D.png';
import esportIcon from './dashboard/newDashboard/sportIcons/Esport_D.png';
import footBallIcon from './dashboard/newDashboard/sportIcons/Football_D.png';
import golfIcon from './dashboard/newDashboard/sportIcons/Golf_D.png';
import hockeyIcon from './dashboard/newDashboard/sportIcons/Hockey_D.png';
import handballIcon from './dashboard/newDashboard/sportIcons/Hanball_D.png';
import mmaIcon from './dashboard/newDashboard/sportIcons/MMA_D.png';
import politicsIcon from './dashboard/newDashboard/sportIcons/Politic_D.png';
import rubyUnionIcon from './dashboard/newDashboard/sportIcons/RubgyUnion_D.png';
import rugbyLeagueIcon from './dashboard/newDashboard/sportIcons/RudgyLeague_D.png';
import snookerIcon from './dashboard/newDashboard/sportIcons/Snooker_D.png';
import soccerIcon from './dashboard/newDashboard/sportIcons/Soceer_D.png';
import tennisIcon from './dashboard/newDashboard/sportIcons/Tennis_D.png';
import volleyballIcon from './dashboard/newDashboard/sportIcons/Volleyball_D.png';
import waterPoloIcon from './dashboard/newDashboard/sportIcons/WaterPolo_D.png';
import kabaddiIcon from './dashboard/newDashboard/sportIcons/kabaddi_D.png';

import custom_live_chat_icon from './custom_live_chat_icon.png';
import helpCenter from './help-center.png';
import helpCenterLine from './helpcenter-line.png';

import sidebar_livechat_icon from './sidebar/sidebar_livechat_iconNEW.png';
import sidebar_email_icon from './sidebar/sidebar_email_iconNEW.png';
import sidebar_facebook_icon from './sidebar/sidebar_facebook_iconNEW.png';
import sidebar_messenger_icon from './sidebar/sidebar_messenger_iconNEW.png';
import sidebar_faq_icon from './sidebar/sidebar_faq_iconNEW.png';

import sidebar_livechat_icon_event from './sidebar/sidebar_livechat_icon_event.png';
import sidebar_email_icon_event from './sidebar/sidebar_email_icon_event.png';
import sidebar_facebook_icon_event from './sidebar/sidebar_facebook_icon_event.png';
import sidebar_messenger_icon_event from './sidebar/sidebar_messenger_icon_event.png';
import sidebar_faq_icon_event from './sidebar/sidebar_faq_icon_event.png';

import socialMedia_liveChat from './sidebar/socialMedia_liveChat.gif';
import socialMedia_telegram from './sidebar/socialMedia_telegram.gif';
import socialMedia_free_r5 from './sidebar/socialMedia_Free R5_V2.gif';
import socialMedia_lucky_spin from './sidebar/socialMedia_Lucky_Spin.gif';

import ip_restricted from './ip_restriction.png';
import ip_restricted_betleon_logo from './ipRestriction/ip_restriction_betleon_logo.png';
import ip_restriction_icon from './ipRestriction/ip_restriction_icon.png';

import signupBanner from './signup/banner.png';
import signupBannerLogo from './signup/banner-logo.png';
import signupBannerNoLogo from './signup/signupBanner-no-logo.webp';
import signupBannerMobile from './signup/signupBanner-Mobile.webp';
import registerBannerNoLogo from './signup/registerBanner-no-logo.webp';
import chevronLeft from './dashboard/newDashboard/chevronLeft.png';
import chevronRight from './dashboard/newDashboard/chevronRight.png';
import chevronDown from './dashboard/newDashboard/chevron-down.png';
import chevronUp from './dashboard/newDashboard/chevron-up.png';
import chevronRightBold from './dashboard/newDashboard/chevronRightBold.png';

import aboutUs from './aboutUs.png';

// sports
import teamAway from './sports/team_away.png';
import teamHome from './sports/team_home.png';

// new mobile banners
import gameBannerCardGameMobile from './game/banner/game_banner_card_game_mobile.png';
import gameBannerCardGameMobile2 from './game/banner/game_banner_card_game_mobile_2.png';
import gameBannerCasinoMobile from './game/banner/game_banner_casino_mobile.png';
import gameBannerCasinoMobile2 from './game/banner/game_banner_casino_mobile_2.png';
import gameBannerInstantGameMobile from './game/banner/game_banner_instant_game_mobile.png';
import gameBannerInstantGameMobile2 from './game/banner/game_banner_instant_game_mobile_2.png';
import gameBannerLiveMobile from './game/banner/game_banner_live_mobile.png';
import gameBannerLiveMobile2 from './game/banner/game_banner_live_mobile_2.png';
import gameBannerSlotsMobile from './game/banner/game_banner_slots_mobile.png';
import gameBannerSlotsMobile2 from './game/banner/game_banner_slots_mobile_2.png';
import gameBannerTableGameMobile from './game/banner/game_banner_table_game_mobile.png';
import gameBannerTableGameMobile2 from './game/banner/game_banner_table_game_mobile_2.png';
import depositReminderField from './deposit/deposit_reminder_field.png';
import gameBannerSlots3 from './game/banner/game_banner_slots_3.png';
import gameBannerSlotsMobile3 from './game/banner/game_banner_slots_3_mobile.png';
import gameBannerSlots4 from './game/banner/game_banner_slots_4.png';
import gameBannerSlotsMobile4 from './game/banner/game_banner_slots_4_mobile.png';

import iplFloatIcon from './dashboard/ipl_float_icon.png';

import home_filter_recently_played_icon from './dashboard/newDashboard/filter/home_filter_recently_played_icon.png';
import home_filter_fishing_icon from './dashboard/newDashboard/filter/home_filter_fishing_icon.png';
import home_filter_card_games_icon from './dashboard/newDashboard/filter/home_filter_card_games_icon.png';
import home_filter_live_casino_icon from './dashboard/newDashboard/filter/home_filter_live_casino_icon.png';
import home_filter_slots_icon from './dashboard/newDashboard/filter/home_filter_slots_icon.png';
import home_filter_popular_icon from './dashboard/newDashboard/filter/home_filter_popular_icon.png';
import home_filter_sports_icon from './dashboard/newDashboard/filter/home_filter_sports_icon.png';
import home_filter_all_icon from './dashboard/newDashboard/filter/home_filter_all_icon.png';
import home_filter_recently_played_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_recently_played_icon.png';
import home_filter_fishing_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_fishing_icon.png';
import home_filter_card_games_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_card_games_icon.png';
import home_filter_live_casino_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_live_casino_icon.png';
import home_filter_slots_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_slots_icon.png';
import home_filter_popular_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_popular_icon.png';
import home_filter_sports_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_sports_icon.png';
import home_filter_all_icon_hover from './dashboard/newDashboard/filter/hover/home_filter_all_icon.png';
import icons8grid50 from './dashboard/newDashboard/filter/icons8-grid-50.png';

import SecondLayerTelegramDesktopEN_BG from './secondLayerBanner/background/desktop/01_Telegram_EN.gif';
import SecondLayerTelegramDesktopPT_BG from './secondLayerBanner/background/desktop/01_Telegram_PT.gif';
import SecondLayerTelegramMobileEN_BG from './secondLayerBanner/background/mobile/01_Telegram_EN.gif';
import SecondLayerTelegramMobilePT_BG from './secondLayerBanner/background/mobile/01_Telegram_PT.gif';
import SecondLayerLuckySpinDesktopEN_BG from './secondLayerBanner/background/desktop/02_LuckySpin_EN.gif';
import SecondLayerLuckySpinDesktopPT_BG from './secondLayerBanner/background/desktop/02_LuckySpin_PT.gif';
import SecondLayerLuckySpinMobileEN_BG from './secondLayerBanner/background/mobile/02_LuckySpin_EN.gif';
import SecondLayerLuckySpinMobilePT_BG from './secondLayerBanner/background/mobile/02_LuckySpin_PT.gif';
import SecondLayerGiftDesktopEN_BG from './secondLayerBanner/background/desktop/03_Gift_EN.gif';
import SecondLayerGiftDesktopPT_BG from './secondLayerBanner/background/desktop/03_Gift_PT.gif';
import SecondLayerGiftMobileEN_BG from './secondLayerBanner/background/mobile/03_Gift_EN.gif';
import SecondLayerGiftMobilePT_BG from './secondLayerBanner/background/mobile/03_Gift_PT.gif';

export { default as VIPCardBG } from './vip/bonusImages/CardBG.webp';
export { default as VIPDailywithrawal } from './vip/bonusImages/01_dailywithrawal.webp';
export { default as VIPBirthdaybonus } from './vip/bonusImages/02_Birthdaybonus.webp';
export { default as VIPUpgradebonus } from './vip/bonusImages/03_Upgradebonus.webp';
export { default as VIPCasinoSlotRebate } from './vip/bonusImages/04_CasinoSlotRebate.webp';
export { default as VIPSportsRebate } from './vip/bonusImages/05_SportsRebate.webp';
export { default as VIPSlotFishing } from './vip/bonusImages/06_SlotFishing.webp';
export { default as VIPLiveCasino } from './vip/bonusImages/07_LiveCasino.webp';

export { default as VipTrophyNormal } from './vip/trophy/normal.webp';
export { default as VipTrophyBronze } from './vip/trophy/bronze.webp';
export { default as VipTrophySilver } from './vip/trophy/silver.webp';
export { default as VipTrophyGold } from './vip/trophy/gold.webp';
export { default as VipTrophyPlatinum } from './vip/trophy/platinum.webp';
export { default as VipTrophyDiamond } from './vip/trophy/diamond.webp';

export { default as CampaignsBanner } from './campaigns/banner.png';
export { default as CampaignsMobileBanner } from './campaigns/mobilebanner.png';
export { default as Banner7xwin } from './campaigns/banner7xwin.png';
export { default as Mobilebanner7xwin } from './campaigns/mobilebanner7xwin.png';
export { default as CampaignsRank1 } from './campaigns/rank1.png';
export { default as CampaignsRank2 } from './campaigns/rank2.png';
export { default as CampaignsRank3 } from './campaigns/rank3.png';
export { default as CampaignsPrizeBg1 } from './campaigns/prizes/bg1.png';
export { default as CampaignsPrizeBg2 } from './campaigns/prizes/bg2.png';
export { default as CampaignsPrizeBg3 } from './campaigns/prizes/bg3.png';
export { default as CampaignsPrizeBg4 } from './campaigns/prizes/bg4.png';
export { default as CampaignsPrize1 } from './campaigns/prizes/1.png';
export { default as CampaignsPrize2 } from './campaigns/prizes/2.png';
export { default as CampaignsPrize3 } from './campaigns/prizes/3.png';
export { default as CampaignsPrize4 } from './campaigns/prizes/4.png';

export { default as BannerHome } from './dashboard/banner/1.png';
export { default as BannerBtm1 } from './dashboard/btmBanner/1.png';
export { default as BannerBtm2 } from './dashboard/btmBanner/2.png';
export { default as BannerBtm3 } from './dashboard/btmBanner/3.png';

export { default as DepositGift } from './deposit/gift.png';

export { default as FooterProvider1 } from './footer/providers/1.png';
export { default as FooterProvider2 } from './footer/providers/2.png';
export { default as FooterProvider3 } from './footer/providers/3.png';
export { default as FooterProvider4 } from './footer/providers/4.png';
export { default as FooterProvider5 } from './footer/providers/5.png';
export { default as FooterProvider6 } from './footer/providers/6.png';
export { default as FooterProvider7 } from './footer/providers/7.png';
export { default as FooterProvider8 } from './footer/providers/8.png';
export { default as FooterProvider9 } from './footer/providers/9.png';
export { default as FooterProvider10 } from './footer/providers/10.png';

export { default as SideMenu1 } from './navMenu/slideMenu/new/1.png';
export { default as SideMenu2 } from './navMenu/slideMenu/new/2.png';
export { default as SideMenu3 } from './navMenu/slideMenu/new/3.png';
export { default as SideMenu4 } from './navMenu/slideMenu/new/4.png';
export { default as SideMenu5 } from './navMenu/slideMenu/new/5.png';
export { default as SideMenu6 } from './navMenu/slideMenu/new/6.png';
export { default as SideMenu7 } from './navMenu/slideMenu/new/7.png';
export { default as SideMenu8 } from './navMenu/slideMenu/new/8.png';
export { default as SideMenu9 } from './navMenu/slideMenu/new/9.webp';
export { default as SideMenu10 } from './navMenu/slideMenu/new/10.webp';
export { default as SideMenu11 } from './navMenu/slideMenu/new/11.webp';
export { default as SideMenu12 } from './navMenu/slideMenu/new/12.webp';
export { default as SideMenu13 } from './navMenu/slideMenu/new/13.webp';
export { default as SideMenuBg1 } from './navMenu/slideMenu/new/bg-1.webp';
export { default as SideMenuBg2 } from './navMenu/slideMenu/new/bg-2.webp';
export { default as SideMenuBg3 } from './navMenu/slideMenu/new/bg-3.webp';
export { default as SideMenuBg4 } from './navMenu/slideMenu/new/bg-4.webp';
export { default as SideMenuBg5 } from './navMenu/slideMenu/new/bg-5.webp';
export { default as SideMenuBg6 } from './navMenu/slideMenu/new/bg-6.webp';
export { default as SideMenuBg7 } from './navMenu/slideMenu/new/bg-7.webp';
export { default as SideMenuBg8 } from './navMenu/slideMenu/new/bg-8.webp';
export { default as SideMenuBg9 } from './navMenu/slideMenu/new/bg-9.webp';
export { default as SideMenuBg10 } from './navMenu/slideMenu/new/bg-10.webp';
export { default as SideMenuSub1 } from './navMenu/slideMenu/new/sub/1.png';
export { default as SideMenuSub2 } from './navMenu/slideMenu/new/sub/2.png';
export { default as SideMenuSub3 } from './navMenu/slideMenu/new/sub/3.png';
export { default as SideMenuSub4 } from './navMenu/slideMenu/new/sub/4.png';
export { default as SideMenuSub5 } from './navMenu/slideMenu/new/sub/5.png';
export { default as SideMenuSub6 } from './navMenu/slideMenu/new/sub/6.png';
export { default as SideMenuSub7 } from './navMenu/slideMenu/new/sub/7.png';
export { default as SideMenuSub8 } from './navMenu/slideMenu/new/sub/8.png';
export { default as SideMenuSub9 } from './navMenu/slideMenu/new/sub/9.png';
export { default as SideMenuSub10 } from './navMenu/slideMenu/new/sub/10.png';
export { default as SideMenuSub11 } from './navMenu/slideMenu/new/sub/11.png';
export { default as SideMenuSub12 } from './navMenu/slideMenu/new/sub/12.png';
export { default as SideMenuSub13 } from './navMenu/slideMenu/new/sub/13.png';

export { default as HomeMiddleBanner } from './dashboard/newDashboard/home-middle-banner.webp';
export { default as HomeMiddleBannerMobile } from './dashboard/newDashboard/home-middle-banner-mobile.webp';
export { default as HomeSuperWin } from './dashboard/newDashboard/home-super-win.webp';
export { default as HomeSuperWinMobile } from './dashboard/newDashboard/home-super-win-mobile.webp';
export { default as HomeSuperWinButton } from './dashboard/newDashboard/home-super-win-button.png';

export { default as GamificationUnbox } from './gamification/unbox.png';
export { default as GamificationUnboxGlow } from './gamification/glow.png';
export { default as GamificationOpened } from './gamification/opened.png';

export { default as GamificationOpened1 } from './gamification/1.webp';
export { default as GamificationOpened2 } from './gamification/2.webp';
export { default as GamificationOpened3 } from './gamification/3.webp';
export { default as GamificationOpened4 } from './gamification/4.webp';
export { default as GamificationCoinfront1 } from './gamification/Coinfront-01.webp';
export { default as GamificationCoinfront2 } from './gamification/Coinfront-02.webp';
export { default as GamificationCoinfront3 } from './gamification/Coinfront-03.webp';
export { default as GamificationCoinfront4 } from './gamification/Coinfront-04.webp';

export { default as JackpotBg } from './jackpot/bg.png';
export { default as JackpotTrophy } from './jackpot/trophy.png';
export { default as JackpotTrophyEffect } from './jackpot/effect.png';
export { default as JackpotTrophySmall } from './jackpot/trophy-small.png';
export { default as JackpotSpeaker } from './jackpot/Icon.png';
export { default as JackpotMaskGroup } from './jackpot/Mask group.webp';
export { default as JackpotMaskGroupMobile } from './jackpot/Mask group mobile.webp';
export { default as JackpotBonus } from './jackpot/bonus.png';
export { default as JackpotBronze } from './jackpot/Bronze.png';
export { default as JackpotSilver } from './jackpot/Silver.png';
export { default as JackpotGold } from './jackpot/Gold.png';

export { default as marqueeDesktopEN } from './marquee/Desktop/Desktop_EN_600x700.webp';
export { default as marqueeDesktopPT } from './marquee/Desktop/Desktop_PT_600x700.webp';
export { default as marqueeAndroidEN } from './marquee/Android/Android_EN_600x700.webp';
export { default as marqueeAndroidPT } from './marquee/Android/Android_PT_600x700.webp';
export { default as marqueeIosEN } from './marquee/IOS/IOS_EN_600x700.webp';
export { default as marqueeIosPT } from './marquee/IOS/IOS_PT_600x700.webp';

export { default as ErrorTryAgain } from './ppx7_tryAgain_v2.webp';

export const CustomLiveChatIcon = custom_live_chat_icon;
export const HelpCenter = helpCenter;
export const HelpCenterLine = helpCenterLine;
export const bgVIP = bgVip;

export const loadingGif = loading_gif;
export const GetBonus = getBonus;

export const GameProvider_youlian = youlian;
export const GameProvider_onextwogaming = onextwogaming;
export const GameProvider_betsoft = betsoft;
export const GameProvider_biggaming = biggaming;

export const GameProvider_BnG = BnG;
export const GameProvider_evolution = evolution;
export const GameProvider_EvoPlay = EvoPlay;
export const GameProvider_ezugi = ezugi;
export const GameProvider_habanero = habanero;
export const GameProvider_hacksaw = hacksaw;
export const GameProvider_jdb = jdb;
export const GameProvider_jili = jili;
export const GameProvider_joker = joker;
export const GameProvider_kingmaker = kingmaker;

export const GameProvider_live22 = live22;
export const GameProvider_m8bet = m8bet;
export const GameProvider_microgaming = microgaming;
export const GameProvider_Netent = Netent;
export const GameProvider_nolimitcity = nolimitcity;
export const GameProvider_OneTouch = OneTouch;
export const GameProvider_playnGO = playnGO;
export const GameProvider_Playson = Playson;
export const GameProvider_playtech = playtech;
export const GameProvider_pragmaticplay = pragmaticplay;

export const GameProvider_quickspin = quickspin;
export const GameProvider_redtiger = redtiger;
export const GameProvider_relaxgaming = relaxgaming;
export const GameProvider_sexybaccarat = sexybaccarat;
export const GameProvider_spadegaming = spadegaming;
export const GameProvider_Spinomenal = Spinomenal;
export const GameProvider_superspade = superspade;
export const GameProvider_sv388 = sv388;
export const GameProvider_wazdan = wazdan;
export const GameProvider_YGGDRASIL = YGGDRASIL;
export const GameProvider_Pinnacle = pinnacleLogo;
export const GameProvider_Allbet = allbetLogo;

export const cimb = cimb_logo;
export const hlb = hl_logo;
export const mbb = maybank_logo;
export const pbe = pb_logo;
export const rhb = rhb_logo;
export const bsn = bsn_logo;
export const ambank = ambank_logo;
export const ewallet = ewallet_logo;

export const PaymentSelectedambank_logo = ambank_logo_payment_selected;
export const PaymentSelectedbsn_logo = bsn_logo_payment_selected;
export const PaymentSelectedcimb_logo = cimb_logo_payment_selected;
export const PaymentSelectedduitNow_logo = duitNow_logo_payment_selected;
export const PaymentSelectedhl_logo = hl_logo_payment_selected;
export const PaymentSelectedmaybank_logo = maybank_logo_payment_selected;
export const PaymentSelectedpb_logo = pb_logo_payment_selected;
export const PaymentSelectedtng_logo = tng_logo_payment_selected;
export const PaymentSelectedrhb_logo = rhb_logo_payment_selected;

export const background = bg;
export const noDataIcon = noData;
export const logoIcon = logo;
export const Hamburgersvg = hamburgersvg;

export const profileDepositIcon = profile_deposit_icon;
export const profileWithdrawIcon = profile_withdraw_icon;
export const profileHistoryIcon = profile_history_icon;
export const logoTestIcon = logo_test_icon;
export const profileMyProfileIcon = profile_my_profile_icon;
export const profileChangePasswordIcon = profile_change_password_icon;
export const profileIogoutIcon = profile_logout;
export const logoutIcon = logout_icon;
export const profileBankInfoIcon = profile_bank_info_icon;
export const profileLanguageIcon = profile_language_icon;
export const profileCrownIcon = profile_crown_icon;
export const profileHelpIcon = profile_help_icon;
export const profileLogoutIcon = profile_logout_icon;
export const profileMyBetsIcon = profile_my_bets_icon;
export const profilePaymentHistoryIcon = profile_payment_history_icon;
export const profilePersonalDataIcon = profile_personal_data_icon;
export const profileSettingIcon = profile_setting_icon;
export const profileSpecialOfferIcon = profile_special_offer_icon;
export const profileWalletIcon = profile_wallet_icon;
export const verifyInProgressIcon = verify_in_progress_icon;
export const verificationFailIcon = verification_fail_icon;
export const verifyEmailIcon = verify_email_icon;
export const verifyPhoneIcon = verify_phone_icon;
export const verifyIdentityIcon = verify_identity_icon;
export const verifyFailIconSmall = verify_fail_icon_small;
export const verifyPassIconSmall = verify_pass_icon_small;

export const Languagesvg = languagesvg;
export const languageIcon = language;
export const chineseLanguageLogo = chinese_language_logo;
export const malayLanguageLogo = malay_language_logo;
export const portugueseLanguageLogo = portuguese_language_logo;
export const avatarIcon = avatar_icon;
export const bankCardIcon = card_icon;
export const usdtIcon = usdt_icon;
export const emptyBankListIcon = empty_banklist;
export const topUpUsdtLogo = top_up_usdt_logo;
export const topUpLogo = top_up_logo;
export const duitNowIcon = duitNow_Icon;
export const speakerIcon = speakerImg;

export const walletDepositIcon = walletDeposit;
export const walletWithdrawalIcon = walletWithdrawal;
export const walletHistoryIcon = walletHistory;

export const walletDepositSelectedIcon = walletDepositSelected;
export const walletWithdrawalSelectedIcon = walletWithdrawalSelected;
export const walletHistorySelectedIcon = walletHistorySelected;

export const duitNowLogo = duitNow_logo;
export const tngMerchant = tng_merchant;
export const tngLogo = tng_logo;

export const GameBanner4d = gameBanner4d;
export const GameBannerCard = gameBannerCard;
export const GameBannerCard2 = gameBannerCard2;
export const GameBannerCasino = gameBannerCasino;
export const GameBannerCasino2 = gameBannerCasino2;
export const GameBannerTable = gameBannerTable;
export const GameBannerTableBr = gameBannerTableBR;
export const GameBannerInstantGameBr = gameBannerInstantGameBR;
export const GameBannerInstantGame = gameBannerInstantGame;
export const GameBannerLive = gameBannerLive;
export const GameBannerLiveBr = gameBannerLiveBR;
export const GameBannerSport = gameBannerSport;
export const GameBannerSportBr = gameBannerSportBR;
export const GameBannerFishing = gameBannerFishing;
export const GameBannerFishingBr = gameBannerFishingBR;
export const GameBannerSlots = gameBannerSlots;
export const GameBannerSlotsBr = gameBannerSlotsBR;

export const CryptoIconDeposit = cryptoIconDeposit;
export const CustomerServiceIconDeposit = customerServiceIconDeposit;
export const OnlineTransferIconDeposit = onlineTransferIconDeposit;
export const VpayIconDeposit = vpayIconDeposit;
export const RarepidIconDeposit = rarepidIconDeposit;
export const OspayIconDeposit = ospayIconDeposit;
export const DepositSuccess = depositSuccess;
export const DepositSuccessGreen = depositSuccessGreen;
export const DepositFail = depositFail;

// vip
export const VipNormal = vip_normal;
export const VipBronze = vip_bronze;
export const VipSilver = vip_silver;
export const VipGold = vip_gold;
export const VipPlatinum = vip_platinum;
export const VipDiamond = vip_diamond;

export const TwitterLogo = twitterLogo;
export const LineLogo = lineLogo;
export const YoutueLogo = youtueLogo;
export const TelegramLogo = telegramLogo;
export const fbIcon = fb;
export const igIcon = ig;
export const youtubeIcon = youtube;
export const waIcon = wa;
export const livechatIcon = livechat;
export const tgIcon = tg;
export const wechatIcon = wechat;
export const Tiktok = tiktok;

// Hamburger menu
export const menuHistoryIcon = menu_history;
export const menuHomeIcon = menu_home;
// export const menuLanguageIcon = menu_language;
export const menuLogoutIcon = menu_logout;
export const menuProfileIcon = menu_profile;
export const menuPromotionIcon = menu_promotion;
export const menuWalletIcon = menu_wallet;
export const faqIcon = faq_icon;
export const menuReferral = menu_referral;
export const menuVip = menu_vip;
export const topmenuprofile = top_menu_profile;
export const topmenunotificationIcon = top_menu_notification;

export const CheckinCharacter1 = checkinCharacter1;
export const CheckinCharacter2 = checkinCharacter2;
export const CheckinCharacter3 = checkinCharacter3;
export const CheckinCharacter4 = checkinCharacter4;
export const CheckinTitle = checkinTitle;
export const CheckinBg = checkinBg;

export const Afterclaim = afterclaim;
export const Beforeclaim = beforeclaim;
export const EventscheckinCoin = eventscheckin;
export const EventsCoin = eventsCoin;
export const ClaimNow = claimNow;

export const LuckySpin = luckySpin;
export const WheelSpinOuter = wheelSpinOuter;
export const WheelSpinOuterSlow = wheelSpinOuterSlow;
export const WheelSpinInner = wheelSpinInner;
export const WheelSpinArrow = wheelSpinArrow;
export const WheelSpinButton = wheelSpinButton;

export const ProfileRecommendIcon = profileRecommend_icon;
export const HistoryIcon = history_icon;
export const TransactionIcon = transaction_icon;
export const LockIcon = change_password_icon;
export const GlobalIcon = global_icon;
export const WithdrawPw = withdraw_pw_icon;
export const InviteIcon = inviteIcon;
export const ShareIcon = shareButton;
export const CopyButton = copyButton;
export const referChatPoster = refer_chat_poster;

export const depositIcon = cashInImage;
export const spinIcon = spinImage;
export const ticketIcon = ticketImage;
export const airpod = airPodImage;
export const bag = bagImage;
export const appleWatch = appleWatchImage;
export const gold = goldImage;
export const watch = watchImage;
export const iphone = iphoneImage;

export const NewDashboardWalletBalance = newDashboardWalletBalance;
export const NewDashboardPointBalance = newDashboardPointBalance;

export const newDashboardLineDesktop = newDashboard_lineDesktop;

export const Loading1 = loading1;
export const Loading2 = loading2;
export const Loading3 = loading3;
export const Loading4 = loading4;
export const Loading5 = loading5;
export const DepositPaymentLoading = depositPaymentLoading;

export const gameDefaultImagepng = gameDefaultImage;
export const GameBadge = gameBadge;
export const mobileProviderLogoGif = mobileProviderLogo;
export const webProviderLogoGif = webProviderLogo;

export const menuIconFAQ = menu_icon_faq;
export const menuIconHistory = menu_icon_history;
export const menuIconLiveStream = menu_icon_live_stream;
export const menuIconPromotion = menu_icon_promotion;
export const menuIconReferral = menu_icon_referral;
export const menuIconVIP = menu_icon_vip;
export const menuIconCasino = menu_icon_casino;
export const menuIconFishing = menu_icon_fishing;
export const menuIconLiveCasino = menu_icon_live_casino;
export const menuIconSlots = menu_icon_slots;
export const menuIconSport = menu_icon_sport;
export const menuIconTermsAndConditon = menu_icon_terms_conditions;
export const MenuIconpromotion = menuIconpromotion;
export const MenuIconalllive = menuIconalllive;
export const MenuIconfavorities = menuIconfavorities;
export const MenuIcontopparlays = menuIcontopparlays;
export const MenuChevronLeft = menuchevronLeft;
export const MenuIconhistoryleft = menuIconhistoryleft;
export const MenuIconpromotiongreenleft = menuIconpromotiongreenleft;
export const MenuIconreferralleft = menuIconreferralleft;
export const MenuIcontermleft = menuIcontermleft;
export const MenuIconvipleft = menuIconvipleft;
export const MenuIconfaqleft = menuIconfaqleft;
export const MenuIconIPL = menu_icon_ipl;
export const MenuIconIPLColored = menu_icon_ipl_colored;
export const MenuIconNews = menu_icon_news;

export const ChevronLeft = chevronLeft;
export const ChevronRight = chevronRight;
export const ChevronDown = chevronDown;
export const ChevronRightBold = chevronRightBold;
export const ChevronUp = chevronUp;

export const Menufish = menufish;
export const Menulivecasino = menulivecasino;
export const Menulive = menulive;
export const Menuspot = menuspot;
export const Menuslot = menuslot;
export const Menucasino = menucasino;

export const NavmenuCardGames = navmenuCardGames;
export const NavmenuInstantGames = navmenuInstantGames;
export const NavmenuLiveEvents = navmenuLiveEvents;
export const NavmenuSlot = navmenuSlot;
export const NavmenuLivecasino = navmenuLivecasino;
export const NavmenuTableGames = navmenuTableGames;
export const NavmenuUpcomingMatches = navmenuUpcomingMatches;
export const NavmenuVirtualSports = navmenuVirtualSports;

export const SideMenuBack = sideMenuBack;
export const SideMenuLanguageDown = sideMenuLanguageDown;

export const NavbarCasino = navbarCasino;
export const NavbarCouponRegular = navbarCouponRegular;
export const NavbarMain = navbarMain;
export const NavbarMenu = navbarMenu;
export const NavbarProfile = navbarProfile;
export const NavbarSports = navbarSports;
export const NavbarHistory = navbarHistory;
export const NavbarReferral = navbarReferral;
export const NavbarVip = navbarVip;
export const NavbarPromo = navbarPromo;

export const SportType = sportType;
export const SportTitle = sportTitle;
export const Welcometo = welcometo;
export const SportTeamA = sportTeamA;
export const SportTeamB = sportTeamB;
export const SportBell = sportBell;
export const SportChart = sportChart;
export const SportStar = sportStar;

export const DownloadApk = downloadApk;

export const SnookerIcon = snookerIcon;
export const BasketballIcon = basketballIcon;
export const CricketIcon = cricketIcon;
export const BaseballIcon = baseballIcon;
export const CurlingIcon = curlingIcon;
export const EsportIcon = esportIcon;
export const HandballIcon = handballIcon;
export const SoccerIcon = soccerIcon;
export const PoliticsIcon = politicsIcon;
export const VolleyballIcon = volleyballIcon;
export const TennisIcon = tennisIcon;
export const BadmintonIcon = badmintonIcon;
export const MmaIcon = mmaIcon;
export const RugbyLeagueIcon = rugbyLeagueIcon;
export const RubyUnionIcon = rubyUnionIcon;
export const GolfIcon = golfIcon;
export const WaterPoloIcon = waterPoloIcon;
export const KabaddiIcon = kabaddiIcon;
export const FootBallIcon = footBallIcon;
export const BoxingIcon = boxingIcon;
export const HockeyIcon = hockeyIcon;

export const sidebarLivechatIcon = sidebar_livechat_icon;
export const sidebarEmailIcon = sidebar_email_icon;
export const sidebarFacebookIcon = sidebar_facebook_icon;
export const sidebarMessengerIcon = sidebar_messenger_icon;
export const sidebarFaqIcon = sidebar_faq_icon;

export const sidebarLivechatIconEvent = sidebar_livechat_icon_event;
export const sidebarEmailIconEvent = sidebar_email_icon_event;
export const sidebarFacebookIconEvent = sidebar_facebook_icon_event;
export const sidebarMessengerIconEvent = sidebar_messenger_icon_event;
export const sidebarFaqIconEvent = sidebar_faq_icon_event;

export const ipRestricted = ip_restricted;
export const ipRestrctedLogo = ip_restricted_betleon_logo;
export const ipRestrictedIcon = ip_restriction_icon;

export const SignupBanner = signupBanner;
export const SignupBannerLogo = signupBannerLogo;
export const SignupBannerNoLogo = signupBannerNoLogo;
export const SignupBannerMobile = signupBannerMobile;
export const RegisterBannerNoLogo = registerBannerNoLogo;

export const AboutUs = aboutUs;

export const TeamAway = teamAway;
export const TeamHome = teamHome;

export const GameBannerCardGameMobile = gameBannerCardGameMobile;
export const GameBannerCardGameMobile2 = gameBannerCardGameMobile2;
export const GameBannerCasinoMobile = gameBannerCasinoMobile;
export const GameBannerCasinoMobile2 = gameBannerCasinoMobile2;
export const GameBannerInstantGameMobile = gameBannerInstantGameMobile;
export const GameBannerInstantGameMobile2 = gameBannerInstantGameMobile2;
export const GameBannerLiveMobile = gameBannerLiveMobile;
export const GameBannerLiveMobile2 = gameBannerLiveMobile2;
export const GameBannerSlotsMobile = gameBannerSlotsMobile;
export const GameBannerSlotsMobile2 = gameBannerSlotsMobile2;
export const GameBannerTableGameMobile = gameBannerTableGameMobile;
export const GameBannerTableGameMobile2 = gameBannerTableGameMobile2;
export const DepositReminderField = depositReminderField;
export const GameBannerSlots3 = gameBannerSlots3;
export const GameBannerSlots4 = gameBannerSlots4;
export const GameBannerSlotsMobile3 = gameBannerSlotsMobile3;
export const GameBannerSlotsMobile4 = gameBannerSlotsMobile4;

export const IPLFloatIcon = iplFloatIcon;

export const biggestWinIconHover = biggest_win_icon_hover;
export const biggestWinIcon = biggest_win_icon;
export const latestEarningsIconHover = latest_earnings_icon_hover;
export const latestEarningsIcon = latest_earnings_icon;
export const liveEarningsIconHover = live_earnings_icon_hover;
export const liveEarningsIcon = live_earnings_icon;
export const defaultThumbnailIcon = default_thumbnail_icon;

export const homeFilterRecentlyPlayedIcon = home_filter_recently_played_icon;
export const homeFilterFishingIcon = home_filter_fishing_icon;
export const homeFilterCardGamesIcon = home_filter_card_games_icon;
export const homeFilterLiveCasinoIcon = home_filter_live_casino_icon;
export const homeFilterSlotsIcon = home_filter_slots_icon;
export const homeFilterPopularIcon = home_filter_popular_icon;
export const homeFilterSportsIcon = home_filter_sports_icon;
export const homeFilterAllIcon = home_filter_all_icon;
export const homeFilterRecentlyPlayedIconHover = home_filter_recently_played_icon_hover;
export const homeFilterFishingIconHover = home_filter_fishing_icon_hover;
export const homeFilterCardGamesIconHover = home_filter_card_games_icon_hover;
export const homeFilterLiveCasinoIconHover = home_filter_live_casino_icon_hover;
export const homeFilterSlotsIconHover = home_filter_slots_icon_hover;
export const homeFilterPopularIconHover = home_filter_popular_icon_hover;
export const homeFilterSportsIconHover = home_filter_sports_icon_hover;
export const homeFilterAllIconHover = home_filter_all_icon_hover;
export const Icons8grid50 = icons8grid50;

export const socialMediaLiveChat = socialMedia_liveChat;
export const socialMediaTelegram = socialMedia_telegram;
export const socialMediaFreeR5 = socialMedia_free_r5;
export const socialMediaLuckySpin = socialMedia_lucky_spin;

export const telegramDesktopEN_BG = SecondLayerTelegramDesktopEN_BG;
export const telegramDesktopPT_BG = SecondLayerTelegramDesktopPT_BG;
export const telegramMobileEN_BG = SecondLayerTelegramMobileEN_BG;
export const telegramMobilePT_BG = SecondLayerTelegramMobilePT_BG;
export const luckySpinDesktopEN_BG = SecondLayerLuckySpinDesktopEN_BG;
export const luckySpinDesktopPT_BG = SecondLayerLuckySpinDesktopPT_BG;
export const luckySpinMobileEN_BG = SecondLayerLuckySpinMobileEN_BG;
export const luckySpinMobilePT_BG = SecondLayerLuckySpinMobilePT_BG;
export const giftDesktopEN_BG = SecondLayerGiftDesktopEN_BG;
export const giftDesktopPT_BG = SecondLayerGiftDesktopPT_BG;
export const giftMobileEN_BG = SecondLayerGiftMobileEN_BG;
export const giftMobilePT_BG = SecondLayerGiftMobilePT_BG;
